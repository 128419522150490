// jQuery
require('jquery');
require('jquery.scrollto');
require('jquery.scrollbar');
require('slick-carousel/slick/slick.js');
// Сторонние библиотеки
var anime = require('animejs');
window.anime = anime;
require('moment');
require('toastr');
require('pace-progress');
require('Detect.js');
// Angular
require('angular');
require('angular-animate');
require('@uirouter/angularjs');
require('angular-ui-router-anim-in-out');
require('ngstorage');
require('angular-loading-bar');
require('angular1-text-mask');

